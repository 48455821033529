<template>
  <CreateAffiliateProduct :id="id" />
</template>

<script>
import CreateAffiliateProduct from "./CreateAffiliateProduct.vue";

export default {
  components: { CreateAffiliateProduct },
  computed: {
    id() {
      return this.$route.params.id;
    },
  }
};
</script>
